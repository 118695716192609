import Glide, {Autoplay, Breakpoints, Controls, Swipe,} from "@glidejs/glide/dist/glide.modular.esm";
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 400) {
            $("#backtotop")
                .removeClass("opacity-0 invisible")
                .addClass("opacity-100 visible");
        } else {
            $("#backtotop")
                .removeClass("opacity-100 visible")
                .addClass("opacity-0 invisible");
        }
    });
    
    $("#backtotop").click(function () {
        $("html, body").animate(
            {
                scrollTop: "0px",
            },
            500
        );
    });

    //LIGHTGALLERY WORDPRESS
    $(".wp-block-gallery,.lightgallery")
        .lightGallery({
            download: false,
            selector: "a",
        })
        .on("onBeforeSlide.lg", function () {
            $(".lg-backdrop").css("opacity", "0.8");
            $(".lg-sub-html").css("opacity", "0");
        });

    //HEADER
    $('#menu-burger').click(function (){
       $('#mobile-menu').toggleClass('open');
       $(document.body).toggleClass('overflow-hidden');
    });
    //MENU SUB-MENU
    $('#mobile-menu .menu-item.menu-item-has-children').click(function (){
        if(window.matchMedia(`(max-width: 1280px)`).matches){
            $(this).toggleClass('open');
        }
    });

    //MODAL
    $('.modal--event').click(function (e) {
        e.preventDefault();
        var content = $(this).find('.modal--content').html();
        openModal(content);
    });
    $('.modal--bg, #modal .close').click(function () {
        closeModal();
    });
    function openModal(content) {
        $('#modal').find('.container').append(content);
        $('.modal--container').addClass('show');
        $('html, body').addClass('overflow-hidden');
    }
    function closeModal() {
        $('.modal--container').removeClass('show');
        $('html, body').removeClass('overflow-hidden');
        setTimeout(function () {
            $('#modal').find('.container').empty();
        }, 300);
    }

    //ACCORDIONS
    $('.accordion .accordion--label').click(function (){
        $(this).toggleClass('active');
        $(this).parent().find('.accordion--content').stop().slideToggle(400);
    });
    
    let temoignagesSwiper = new Swiper(".temoignagesSwiper", {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            1024: {
                direction: "vertical",
            },
        }
    });

    let articlesSwiper = new Swiper(".articlesSwiper", {
        centeredSlides: true,
        slidesPerView: "auto",
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                loop: false,
                centeredSlides: false,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 3,
                loop: false,
                centeredSlides: false,
                spaceBetween: 30,
            },

        }
    });


    //SLIDER
    function GlideAutoHeight(Glide, Components, Events) {
        const Component = {
            mount() {
                if (!Glide.settings.autoHeight) return;
                Components.Html.track.style.transition = 'height 200ms ease-in-out';
                this.updateTrackHeight();
            },

            updateTrackHeight() {
                if (!Glide.settings.autoHeight) return;

                setTimeout(() => {
                    const activeSlides = Components.Html.slides.filter((slide, index) => {
                        return (index >= Glide.index && index <= (Glide.index-1) + Glide.settings.perView);
                    });

                    const newMaxHeight = activeSlides.reduce((maxHeight, slide) => {
                        console.log(maxHeight, slide.offsetHeight);
                        return Math.max(maxHeight, slide.offsetHeight);
                    }, 0);

                    const glideTrack = Components.Html.track;
                    if (newMaxHeight !== glideTrack.offsetHeight) {
                        glideTrack.style.height = `${newMaxHeight}px`;
                    }
                }, 100);
            },
        };

        Events.on('run', () => {Component.updateTrackHeight();});
        Events.on('update', () => {Component.updateTrackHeight();});
        Events.on('resize', () => {Component.updateTrackHeight();});

        return Component;
    }

    $('.glide').each(function () {
        new Glide($(this)[0], {
            perView: 1,
            focusAt: "center",
            gap: 0,
            autoHeight: true,
        }).mount({Autoplay, Breakpoints, Controls, Swipe, GlideAutoHeight});
    });

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    
});